export default {
  baseUrl: 'collections/get/',

  /**
   * @name metaData
   */
  getMetaData: {
    url: 'metadata',
    method: 'post',
    // token: false,
    data: {
      filter: {
        // title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        // title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
    },
    fun: ({ entries, fields }) => {
      const tmp = entries.map((data) => ({
        title: data.title,
        // description: data.description,
        // keywords: data.keywords,
        // ogTitle: data.ogTitle,
        // ogDescription: data.ogDescription,
        // ogImage: data.ogImage
        //   ?.path?.[0] === '/' ? data.ogImage.path.slice(1) : data.ogImage?.path,
        // ogUrl: data.ogUrl,
        // ogType: 'website',
        path: data.map.map((item) => item.display),
      }));

      return { entries: tmp, fields };
    },
  },
};
