export default {
  /**
   * @name 登入
   */
  baseUrl: 'collections/get',
  index: {
    url: '/result',
    method: 'post',
    data: {
      filter: true,
      fields: true,
      limit: true,
      skip: true,
      sort: true,
    },
    token: true,
  },
};
