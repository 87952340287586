export default {
  /**
   * @name 登入
   */
  baseUrl: 'cockpit',
  index: {
    url: '/addAssets',
    method: 'file',
    data: {
      a: true,
      files: {},
    },
    token: true,
  },
};
