import news from './news';
import achievement from './achievement';
import contact from './contact';
import addAssets from './addAssets';
import seo from './seo';

export default {
  news,
  achievement,
  contact,
  addAssets,
  seo,
};
