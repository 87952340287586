export default {
  /**
   * @name 登入
   */
  baseUrl: 'collections/save/',
  company: {
    url: 'contact_b2b',
    method: 'post',
    data: {
      data: {
        company: true,
        contact: true,
        mobile: true,
        email: true,
        memo: true,
        agree: true,
        status: true,
        link: true,
      },
    },
    token: true,
  },
  recruit: {
    url: 'contact_b2c',
    method: 'post',
    data: {
      cname: true,
      mobile: true,
      email: true,
      memo: true,
      agree: true,
      status: true,
      link: true,
    },
    token: true,
  },
};
